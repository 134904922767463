<template>
    <div class="client-page">
        <router-view />
        <van-tabbar v-if="$route.meta.tabbar > -1" v-model="tabActive" active-color="#DF5F0B" inactive-color="#808080">
            <van-tabbar-item icon="logistics" to="/client/home">货物管理</van-tabbar-item>
            <van-tabbar-item icon="desktop-o" to="/client/workbench">工作台</van-tabbar-item>
            <van-tabbar-item icon="user-o" to="/client/my">个人中心</van-tabbar-item>
        </van-tabbar>
    </div>
</template>

<script setup>
import { ref, watch } from "vue";
import usePublic from "@/hooks/usePublic";

let { $route, $router, $store } = usePublic();

// tabbar功能
let tabActive = ref($route.meta.tabbar);

watch(
    () => $route,
    (val) => {
        tabActive.value = $route.meta.tabbar;
    },
    { deep: true }
);
</script>
